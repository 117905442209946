<template>
  <div>
  </div>
</template>

<script>
import { eventBus } from "@/main";

export default {
  name: 'EDCWebsocket',

  props: {
    initialWebsocketUrl: {
      type: String,
      default: 'ws://localhost:5000/ws/',
    },
  },

  data() {
    return {
      editableWebsocketUrl: this.initialWebsocketUrl,
      socket: null,
      isConnected: false,
      isProcessing: false,
      acknowledgment: null,
      dataResponse: null,
      error: null,
      timeouts: [],
      transactionTypes: {
        saleByCard: {
          transactionCode: '20',
          fields: [
            { FieldType: 'A1', FieldName: 'Invoice No.' },
            { FieldType: 'A3', FieldName: 'VAT Refund Amount' },
            { FieldType: '40', FieldName: 'Amount' },
          ],
        },
        saleByQR: {
          transactionCode: 'QR',
          fields: [
            { FieldType: 'A1', FieldName: 'Invoice No.' },
            { FieldType: 'A3', FieldName: 'VAT Refund Amount' },
            { FieldType: '40', FieldName: 'Amount' },
          ],
        },
        cancelTransaction: {
          transactionCode: '26',
          fields: [
            { FieldType: '65', FieldName: 'Invoice Number' },
            { FieldType: '01', FieldName: 'Approval Code' },
            { FieldType: 'F1', FieldName: 'Card Type' },
          ],
        },
      },
    };
  },

  created() {
    eventBus.$on('connectWebsocket', this.connectWebSocket);
    eventBus.$on('sendTransaction', this.sendTransaction);
    eventBus.$on('disconnectWebsocket', this.disconnectWebSocket);
    eventBus.$on('cancelTransaction', this.clearAllTimeouts);
  },

  beforeDestroy() {
    this.disconnectWebSocket();
    eventBus.$off('connectWebsocket', this.connectWebSocket);
    eventBus.$off('sendTransaction', this.sendTransaction);
    eventBus.$off('disconnectWebsocket', this.disconnectWebSocket);
    eventBus.$off('cancelTransaction', this.clearAllTimeouts);
  },

  watch: {
    isConnected(val) {
      eventBus.$emit('isEdcWebsocketConnected', val);
    },
    error(val) {
      if (val) {
        console.error('EDC Payment WS error', val);
      }
    },
  },

  methods: {
    connectWebSocket() {
      if (this.socket) {
        this.disconnectWebSocket();
      }

      this.socket = new WebSocket(this.editableWebsocketUrl);

      this.socket.onopen = () => {
        this.isConnected = true;
        console.log('WebSocket connected');
      };

      this.socket.onmessage = (event) => {
        const response = JSON.parse(event.data);
        this.handleResponse(response);
      };

      this.socket.onclose = () => {
        this.isConnected = false;
        console.log('WebSocket disconnected');
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        this.handleError('Error connecting to WebSocket server');
      };
    },

    disconnectWebSocket() {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
    },

    handleResponse(response) {
      console.log('Websocket response', response);
      //Acknowledgement
      if (response.AcknowledgeDateTime) {
        this.acknowledgment = response;
        if (response.AcknowledgeCode !== 'AA') {
          this.handleError(`Acknowledge Error: ${JSON.stringify(this.acknowledgment)}`);
        } else {
          this.startDataResponseTimer();
        }
      }

      //EDC response
      else if (response?.PresentationHeader?.ResponseCode === '00') {
        this.dataResponse = this.formatResponseObject(response);
        this.isProcessing = false;
        eventBus.$emit('transactionResponse', this.dataResponse);
      } else if (response?.PresentationHeader?.ResponseCode === 'ND') {
        this.handleError('Transaction cancelled by user');
      }

      //API status
      else if (typeof response?.ApiStatus === "boolean") {
        // {
        //   "ApiStatus": false,
        //     "ApiMessage": "Send To DentCloud Api Unsuccess, Error: No connection could be made because the target machine actively refused it. (localhost:7200)"
        // }
        //TODO handle DentCloud API result
        console.log(`DentCloud API status: ${JSON.stringify(response)}`)

      } else {
        this.handleError(`Transaction Error: ${JSON.stringify(response)}`);
      }
    },

    handleError(errorMessage) {
      this.error = errorMessage;
      eventBus.$emit('transactionError', new Error(errorMessage));
      this.isProcessing = false;
      eventBus.$emit('edcProcessingStatus', false);
    },

    startAcknowledgmentTimer() {
      const timeoutId = setTimeout(() => {
        if (!this.acknowledgment) {
          this.handleError('Acknowledgment not received within 5 seconds.');
        }
      }, 5000);
      this.timeouts.push(timeoutId);
    },

    startDataResponseTimer() {
      const timeoutId = setTimeout(() => {
        if (!this.dataResponse) {
          this.handleError('Data response not received within 1 minute.');
        }
      }, 60000);
      this.timeouts.push(timeoutId);
    },

    sendMessage(message) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify(message));
        this.startAcknowledgmentTimer();
      } else {
        this.handleError('WebSocket is not connected');
      }
    },

    async sendTransaction({ transactionType, amount, receiptNo }) {
      if (!this.isConnected) {
        await this.ensureConnection();
      }

      return new Promise((resolve, reject) => {
        if (!this.isConnected) {
          reject(new Error('Failed to establish WebSocket connection'));
          return;
        }

        this.clearAllTimeouts();
        console.log({ transactionType, amount, receiptNo });
        eventBus.$emit('edcProcessingStatus', true);

        this.acknowledgment = null;
        this.dataResponse = null;
        this.error = null;
        this.isProcessing = true;

        const code = transactionType === 'card' ? '20' : transactionType === 'qr' ? 'QR' : null;
        if (!code) {
          reject(new Error('Invalid transaction type'));
          return;
        }

        const message = {
          ReserveForFurfuresUse: '0000000000',
          PresentationHeader: {
            FormatVersion: '1',
            RequestResponseIndicator: '0',
            TransactionCode: code,
            ResponseCode: '00',
            MoreDataIndicator: '0',
          },
          FieldDatas: [
            { FieldType: 'A1', FieldName: 'Invoice No.', Data: receiptNo || 'REC00001' },
            { FieldType: 'A3', FieldName: 'VAT Refund Amount', Data: '0' },
            { FieldType: '40', FieldName: 'Amount', Data: String(amount) },
          ],
        };

        this.sendMessage(message);

        const handleTransactionResponse = (response) => {
          if (response.error) {
            reject(new Error(response.error));
          } else {
            resolve(this.formatResponseObject(response));
          }
          eventBus.$off('transactionResponse', handleTransactionResponse);
          eventBus.$off('transactionError', this.clearAllTimeouts);
        };

        eventBus.$on('transactionResponse', handleTransactionResponse);
        eventBus.$on('transactionError', this.clearAllTimeouts);
      });
    },

    async ensureConnection() {
      if (!this.isConnected) {
        console.log('Attempting to reconnect WebSocket...');
        this.connectWebSocket();

        await new Promise((resolve, reject) => {
          const timeout = setTimeout(() => {
            reject(new Error('WebSocket connection timeout'));
          }, 5000);

          const checkConnection = setInterval(() => {
            if (this.isConnected) {
              clearInterval(checkConnection);
              clearTimeout(timeout);
              resolve();
            }
          }, 100);
        });
      }
    },

    clearAllTimeouts() {
      this.timeouts.forEach((timeoutId) => clearTimeout(timeoutId));
      this.timeouts = [];
      eventBus.$emit('edcProcessingStatus', false);
    },

    formatResponseObject(inputObj) {
      if (!inputObj.FieldDatas || !Array.isArray(inputObj.FieldDatas)) {
        return {};
      }

      return inputObj.FieldDatas.reduce((result, field) => {
        if (field.FieldName && field.Data) {
          const key = field.FieldName.toLowerCase().replace(/([-_ ][a-z])/g, (group) =>
            group.toUpperCase().replace(/[-_ ]/, '')
          );
          result[key] = field.Data;
        }
        return result;
      }, {});
    },
  },
};
</script>