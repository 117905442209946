var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('b-form',{class:{ 'form-added': _vm.justAdded },on:{"submit":function($event){$event.preventDefault();return _vm.addDrug.apply(null, arguments)}}},[_c('fieldset',{attrs:{"disabled":_vm.mode === 'edit'}},[_c('b-form-group',{attrs:{"label-cols":"3","label":"ผู้ขาย:","label-align":"right","label-class":"font-weight-bold"}},[_c('b-form-select',{class:{ 'is-invalid': _vm.$v.actionUid.$error },attrs:{"options":_vm.getUserList,"value-field":"uid","text-field":"fullName","size":_vm.size,"disabled":_vm.isDashboardRoute},model:{value:(_vm.$v.actionUid.$model),callback:function ($$v) {_vm.$set(_vm.$v.actionUid, "$model", $$v)},expression:"$v.actionUid.$model"}})],1),_c('hr'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"description",fn:function(){return [(_vm.$v.itemList.$error)?_c('div',{staticClass:"error"},[_vm._v(" *กรุณาลงข้อมูลยา และอุปกรณ์ ")]):_c('div',[_vm._v("ตั้งรายการใช้ประจำได้ใน 'Drugs list'")])]},proxy:true}])},[_c('b-input-group',[_c('multiselect',{staticClass:"col p-0 small-text",class:{
                    'is-invalid': _vm.$v.itemList.$error,
                    'multiselect-black-border': !_vm.$v.itemList.$error,
                    'multiselect-md-input': _vm.size != 'sm',
                  },attrs:{"options":_vm.getGroupedItemList,"multiple":false,"group-values":"libs","group-label":"category","group-select":false,"placeholder":"พิมพ์ค้นหารายการยาและอุปกรณ์","custom-label":_vm.customTxDrugsLabel,"track-by":"nameTh","selectLabel":"","selectedLabel":"","deselectLabel":"","disabled":_vm.mode === 'edit'},on:{"input":function($event){return _vm.$refs.drugItemQuantity.$el.focus()}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.$isLabel)?_c('span',[_vm._v(_vm._s(props.option.$groupLabel))]):_c('span',[_c('b-button',{staticClass:"mr-1 small",attrs:{"size":"xs","variant":"outline-info","pressed":props.option.favorite},on:{"click":function($event){$event.stopPropagation();return _vm.favItemtoggle(props.option)}}},[_c('i',{staticClass:"far fa-star"})]),_vm._v(_vm._s(_vm.customTxDrugsLabel(props.option))+" ")],1)]}}]),model:{value:(_vm.$v.itemList.$model),callback:function ($$v) {_vm.$set(_vm.$v.itemList, "$model", $$v)},expression:"$v.itemList.$model"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา")])]),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"info","size":_vm.size},on:{"click":_vm.openItemModal}},[_vm._v("Drugs list")])],1)],1)],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col mb-0"},[_c('b-input-group',{attrs:{"append":"จำนวน","size":_vm.size}},[_c('b-form-input',{ref:"drugItemQuantity",class:{
                  'is-invalid': _vm.$v.quantity.$error,
                },attrs:{"size":_vm.size,"type":"number","number":"","formatter":_vm.toNumber,"lazy-formatter":""},model:{value:(_vm.$v.quantity.$model),callback:function ($$v) {_vm.$set(_vm.$v.quantity, "$model", $$v)},expression:"$v.quantity.$model"}})],1)],1),_c('div',{staticClass:"form-group col mb-0"},[_c('b-input-group',{attrs:{"append":"บาท","size":_vm.size}},[_c('b-form-input',{class:{ 'is-invalid': _vm.$v.price.$error },attrs:{"number":"","placeholder":"฿","lazy-formatter":"","formatter":_vm.toNumber,"size":_vm.size},model:{value:(_vm.$v.price.$model),callback:function ($$v) {_vm.$set(_vm.$v.price, "$model", $$v)},expression:"$v.price.$model"}})],1)],1)]),_c('div',{staticClass:"description",class:{
            error: _vm.$v.price.$error || _vm.$v.quantity.$error,
          }},[_vm._v(" *กรุณาลงจำนวน และราคา ")])],1),_c('b-form-row',{staticClass:"mb-2",class:_vm.size == 'sm' ? 'limit-height-col-73' : 'limit-height-col-110'},[_c('div',{staticClass:"favorite-text-area-container col pr-1"},[_c('b-form-textarea',{ref:"drugsNoteInput",attrs:{"rows":_vm.size === 'sm' ? 3 : 4,"size":_vm.size,"placeholder":"โน๊ตเพิ่มเติม...","no-resize":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('FavNoteButton',{attrs:{"storageName":"favNoteDrugs"},on:{"listClicked":_vm.handleSelectedFavNote}})],1)]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[(_vm.indexEdit === -1)?_c('span',[_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","size":_vm.size,"variant":"primary","disabled":_vm.mode === 'edit'}},[_vm._v(" เพิ่มรายการยา และอุปกรณ์ "),(_vm.writingMode)?_c('span',[_c('span',{staticClass:"d-inline d-md-none d-lg-inline"},[_c('i',{staticClass:"fas fa-angle-double-down"})]),_c('span',{staticClass:"d-none d-md-inline d-lg-none"},[_c('i',{staticClass:"fas fa-angle-double-right"})])]):_c('span',[_c('span',{staticClass:"d-inline d-lg-none"},[_c('i',{staticClass:"fas fa-angle-double-down"})]),_c('span',{staticClass:"d-none d-lg-inline"},[_c('i',{staticClass:"fas fa-angle-double-right"})])])])],1):_c('span',[_c('b-button',{staticClass:"btn btn-warning float-right",attrs:{"type":"submit","size":_vm.size}},[_vm._v(" แก้ไขรายการ ")]),_c('b-button',{staticClass:"btn btn-danger float-right mr-2",attrs:{"size":_vm.size},on:{"click":_vm.setDefaultDrugItem}},[_vm._v(" ยกเลิกแก้ไข ")])],1)])],1)],1)],1),_c('ItemListModal',{ref:"itemListModal",on:{"selectedItem":function($event){_vm.tableSelectedItemList = $event}}}),(_vm.isLoading)?_c('Loading'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }